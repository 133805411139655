import Me from './components/Me';

function App() {
  return (
    <div className='bg-slate-100'>
      <Me />
    </div>
   
  );
}

export default App;
